export const ADJEKTIVER = [
  "Grimme",
  "Ildelugtende",
  "Genbrugelige",
  "Fugtige",
  "Ulækre",
  "Frygtindgydende",
  "Tisfyldte",
  "Flamberede",
  "Mærkelige",
  "Regnvejrs-agtige",
  "Knoglesmækkende",
  "Yderst ubehagelige",
  "Gudsfrygtende",
  "Politi-fraløbende",
  "Lystfiskende",
  "Oldemorfuskende",
  "Klamme",
  "Fucking Ulækre",
  "Smukke",
  "Kærlighedsfyldte",
  "Adrætte",
  "Lange",
  "kålentusiastiske",
  "Kåde",
  "Liderlige",
  "Onkel Reje-lignende",
  "Løgnsagtige",
  "Skattesvindlende",
  "Revelsbengrindende",
  "Larmende",
  "Absorberende",
];

export const NAVNEORD = [
  "Hund",
  "Luderdreng",
  "Pølse",
  "Langefinger",
  "Danske pilsner",
  "Multijuice fra rema",
  "Skildpaddeluder",
  "Sutteflaske",
  "Golfspiller",
  "Stykke Tandkød",
  "Nabo",
  "Abebarn",
  "Umami småkage",
  "Danskvand",
  "Cryptovalutaekspert",
  "Regnbueørred",
  "Dillersnavs",
  "Pelikanåndehul",
  "Røvsnaps",
  "Britney spears på crack",
  "Pruttesovs",
  "Uglediller",
  "Fine lille Diller",
  "Hvalekspert",
  "Drommedar tarm",
  "Flade flødebolle på et fladt flødebollefad",
  "Knoglemussi",
  "Oddersmager",
  "Betonkriminel",
  "Yversutter",
  "Sjove pirat",
  "Kommunistsvin",
  "Yverdreng",
  "Tissebrød",
  "Betændelse",
  "Mussebarn",
  "Elverdreng",
  "Tove Ditlevsen",
  "Ymer",
  "Mayonnaise",
  "Barack Obama",
  "Filurfjæs",
  "Rotte fra Grenaa",
  "Knoglegurgler",
  "Heks i et badekar",
  "Obama på en bred hest",
];

export const UDFORDRINGER = [
  "Du skal binde et par sko sammen. Ekstra credits hvis der er nogen der haar dem på.",
  "Spørg 10 gange i løbet af I aften om der er nogen der har nogle kæledyr",
  "Giv 2 mennesker en teske",
  "Dit mål er at få nogen til at sætte en sang fra camp rock som deres ringetone",
  "Gå tilbage og drik en tår. Det er challenge nok",
  "Sig til folk at du er taknemmelig for at de ikke har hældt dig ned i en brønd endnu",
];

export const CHANCER = [
  "Du bunder",
  "Du drikker 10 tåre",
  "Du går udenfor",
  "Du giver en øl til din højre sidemakker",
  "Du drikker en lille snapsebabse",
  "Du kalder dig selv uggenheim Guggenheim i tredjeperson den næste time",
  "Du kalder dig selv bing bong ugle i tredjeperson den næste time",
  "Du kalder dig selv rygmarv dominator den næste time",
];

export const EMNER = [
  "Hamstere",
  "Sovjetunionens tragiske fald",
  "Din fremtidige navlepiercing",
  "Hvordan dette rum vi er i minder dig om den første gang du havde sex",
  "Tryghedsgarantien fra MCdonalds",
  "At grønland skal til at købe USA",
  "Affaldssorteringens konsekvenser på dit sexdrive",
  "At funktionsharmonik kan fungere i stedet for et kondom",
  "At alles energi er lidt whack",
  "Den nye girafunge i zoologisk have",
  "Hvordan man laver det perfekte papirfly uden at bruge papir",
  "Historien om verdens største garnkugle",
  "Hvordan man taber i sten, papir, saks hver gang",
  "Historien om high five",
  "De 10 bedste måder at udsætte behov på",
  "Hvorfor du aldrig skal stole på en prut",
  "Selfiens historie, og hvordan den ændrede verden",
  "Hvordan du tilbereder en bøf på 1 sekund eller mindre",
  "Den bedste måde at vaske hænder på",
  "Historien om, hvorfor vi bærer tøj, og hvorfor nogle mennesker ikke gør",
  "At man er lidt af en hykler for at hade på sønderjyder hvis man er fra forstæderne",
  "Grunden til, at dine albuer klør",
  "Hvordan man laver et blyanttrick der scorer damer",
  "Hvorfor du ikke skal gå på facebook når du er forkølet",
  "Grunden til, at dine læber bliver tørre, når du er ked af det",
  "At der er mere end én måde at drikke vand på",
  "At staten har hemmeligholdt, at ænderne i parken er frie",
  "Hvordan man lukker afløbet uden at ringe til en VVS-installatør",
];

export const Roles = [
  "Mussebuller knoglefaster. Musse musse, knogle knogle! Farligheden selv kommer. Som Mussebuller Knoglefaster skal du tage en tår hver gang du siger 'Mussebuller'. Desværre skal du altid tale i tredjeperson! Superkraften varer i 10 minutter.",
  "Tralala! Tid til en fest! Den berømte Vodka amor er ankommet. Og den heldige, der får æren af at være Vodka Amor, er intet mindre end dig! Som Vodka Amor kan du få to personer til at blive drinking buddies. Når den ene drikker, drikker den anden. Kærligheden gælder i 15 minutter.",
  "Bing bing bong bong. Kluntede Korkpropper er ankommet! Som Kluntede Korkpropper skal du drikke en shot hver gang, du spilder noget. Så pas på, og hold dine drinks i sikkerhed!",
  "Rodet Råber er klar til festen! Som Rodet Råber skal du drikke en shot hver gang, du roder i tingene. Så sørg for at holde styr på dine ejendele!",
  "UUHHH UUUHHH klap klap klap klap klap. Som Klapsalvet Korktapper skal du tage en shot, hver gang du klapper, uanset om det er for noget godt eller dårligt. Så pas på med at være for ivrig med dine klapsalver!",
  "Der er ikke en ræv tør. Rævende Ravfugl skal drikke en shot, hver gang du spotter en ræv eller et andet dyr. Så hold øjnene åbne og vær klar til at hæve glasset!",
  "Rim lim, mormor klormor, Rødbedeslurpende Rimtudse er kommet! Som Rødbedeslurpende Rimtudse skal du tage en shot, hver gang du rimer på et ord eller en sætning. Så lad dine rim flyde og nyd et skud med hver succesfuld rimekreation!",
  "Uha uha fanfare, nu bliver det farligt. Den udødelige ibberbibber er ankommet. Som den udødelige ibberbibber kan du undgå at skulle drikke i 20 minutter. Du er udrikkelig!",
  "Der sker mærkelige ting ude i skoven. Der er en magisk gøgler der har lært at undvige tricks. Som den magiske gøgler skal du ikke følge nogen regler. Fandme spændende!",
  "Højt på en gren en krage sad. Kragen var grim. Som krage skal du tage en tår. Hæhæ!",
  "ØV. Borgmester Svend er ankommet, og han er kedelig. I løbet af 10 minutter skal du tage et normalt samtaleemne og gjort det til en kedelig politisk samtale. Hvis du ikke gør det skal du drikke",
];

export const QuizQuestions = [
  "Har vedkommende nogensinde spist en hel citron?",
  "Har vedkommende nogensinde sovet med åbne øjne?",
  "Har vedkommende nogensinde prøvet at spise sushi med chokoladesovs?",
  "Har vedkommende nogensinde danset med en kylling?",
  "Har vedkommende nogensinde forsøgt at svømme med klipklapper på fødderne?",
  "Har vedkommende nogensinde sovet med tændt støvsuger?",
  "Har vedkommende nogensinde spillet golf med en skovl?",
  "Har vedkommende nogensinde haft en vandmelon som kæledyr?",
  "Har vedkommende nogensinde forsøgt at flyve udenfor et vindue?",
  "Har vedkommende nogensinde spist pizza med marmelade på?",
  "Har vedkommende nogensinde sovet med sokker på hænderne?",
  "Har vedkommende nogensinde prøvet at svømme i havet midt om natten?",
  "Har vedkommende nogensinde sunget karaoke på et bibliotek?",
  "Har vedkommende nogensinde kørt på en enhjørning?",
  "Har vedkommende nogensinde spist spaghetti med sukker på?",
  "Har vedkommende nogensinde haft et væddemål om at spise en hel rulle toiletpapir?",
  "Har vedkommende nogensinde forsøgt at hoppe fra et træ og lande på et tag?",
  "Har vedkommende nogensinde lavet en snowboardtrick på en sandstrand?",
  "Har vedkommende nogensinde prøvet at sove på loftet af et hus?",
  "Har vedkommende stadig alle sine visdomstænder?",
  "Har vedkommende nogensinde set en enhjørning spise pizza?",
  "Har vedkommende nogensinde haft et hemmeligt sprog, som kun taler til planterne?",
  "Har vedkommende nogensinde prøvet at svømme med en flok pingviner i en swimmingpool?",
  "Har vedkommende nogensinde vundet en spisekonkurrence med brønddæksler som eneste føde?",
  "Har vedkommende nogensinde sovet på en seng lavet af regnbuer?",
  "Har vedkommende nogensinde haft evnen til at forstå dyrenes sprog?",
  "Har vedkommende nogensinde prøvet at jonglere med fiskestænger?",
  "Har vedkommende nogensinde haft en fuldstændig absurd fobi, f.eks. bange for krusninger på vandoverfladen?",
  "Har vedkommende nogensinde opfundet en ny dansstil baseret på at imitere kyllingelort, der falder fra himlen?",
  "Har vedkommende nogensinde haft en mærkelig hobby som at samle på brugte elastikker fra broccoli?",
  "Har vedkommende nogensinde prøvet at jonglere med tøjbøjler?",
  "Har vedkommende nogensinde haft en telepatisk forbindelse til en græskarplante?",
  "Har vedkommende nogensinde set en regnbue lavet af lakrids?",
  "Har vedkommende nogensinde prøvet at ride på en støvsuger?",
  "Har vedkommende nogensinde haft en telefonsamtale med en hval?",
  "Har vedkommende nogensinde spist en hel ost på én bid?",
  "Har vedkommende nogensinde sovet på en seng af marshmallows?",
  "Har vedkommende nogensinde prøvet at jonglere med fiskedej?",
  "Har vedkommende nogensinde vundet en konkurrence i at imitere lyden af en giraf?",
  "Har vedkommende nogensinde haft en mærkelig fascination af at samle på brugte tyggegummi?",
];
