import { useContext, useEffect } from "react";
import useGameExecutor from "../Hooks/useGameExecutor";
import { CountdownContext } from "../Contexts/CountdownContext.js";

const GameExecutor = () => {
  const { executeGame, gameStatus } = useGameExecutor();

  const { countdownState } = useContext(CountdownContext);

  useEffect(() => {
    if (countdownState.currentTime === 0) {
      executeGame();
    }
  }, [countdownState]);

  return (
    <div>
      <button onClick={executeGame}>Start Game</button>
      <p>Game status: {gameStatus}</p>
    </div>
  );
};

export default GameExecutor;
