// playerAtoms.js
import { atom } from "jotai";

// Define the initial state either from local storage or a default value
const initialState = JSON.parse(localStorage.getItem("players")) || {
  Jeppe: [0],
};

export const atomListOfPlayers = atom(
  initialState, // Initial state
  (get, set, update) => {
    // Update function
    // This function will be called to update the atom's state
    const nextState =
      typeof update === "function" ? update(get(atomListOfPlayers)) : update;
    set(atomListOfPlayers, nextState); // Update atom's state
    localStorage.setItem("players", JSON.stringify(nextState)); // Sync with local storage
  }
);
