import React from "react";
import { useContext } from "react";
import { CountdownContext } from "./../Contexts/CountdownContext.js";

/**
 * Pie is a React component that renders a pie chart to represent a countdown.
 * The countdown is based on two atoms: atomCurrentTime and atomInitialTime.
 **/

// Function to clean the percentage of the pie chart
const cleanPercentage = (percentage) => {
  const tooLow = !Number.isFinite(+percentage) || percentage < 0;
  const tooHigh = percentage > 100;
  return tooLow ? 0 : tooHigh ? 100 : +percentage;
};

const Circle = ({ colour, pct }) => {
  const r = 70;
  const circ = 2 * Math.PI * r;
  const strokePct = ((100 - pct) * circ) / 100;
  return (
    <circle
      r={r}
      cx={100}
      cy={100}
      fill="transparent"
      stroke={strokePct !== circ ? colour : ""} // remove colour as 0% sets full circumference
      strokeWidth={"2rem"}
      strokeDasharray={circ}
      strokeDashoffset={pct ? strokePct : 0}
      strokeLinecap="round"
    ></circle>
  );
};

const Text = ({ seconds }) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  const formattedSeconds =
    remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds;

  const formattedTimeFinish = `${formattedMinutes}:${formattedSeconds}`;

  return (
    <text
      x="50%"
      y="50%"
      dominantBaseline="central"
      textAnchor="middle"
      fontSize={"1.5em"}
    >
      {formattedTimeFinish}
    </text>
  );
};

const Pie = () => {
  const { countdownState } = useContext(CountdownContext);

  const pct = cleanPercentage(
    (countdownState.currentTime / countdownState.initiatedTime) * 100
  );

  return (
    <>
      <div className="flex justify-center ">
        <svg width={200} height={200}>
          <g transform={`rotate(-90 ${"100 100"})`}>
            <Circle colour="lightgrey" />
            <Circle colour={"green"} pct={pct} />
          </g>
          <Text seconds={countdownState.currentTime} />
        </svg>
      </div>
    </>
  );
};

export default Pie;
