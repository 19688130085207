import { useAtom } from "jotai";
import { atomListOfPlayers } from "../Components/state/playerAtoms.js";

import { ADJEKTIVER, NAVNEORD, EMNER, CHANCER } from "../Utils/Helpers.js";

export function useRemovePlayer() {
  const [players, setPlayers] = useAtom(atomListOfPlayers);

  const removePlayer = (playerName) => {
    const newState = { ...players };
    delete newState[playerName];
    setPlayers(newState);
  };

  return removePlayer;
}

export function useAddPlayer() {
  const [players, setPlayers] = useAtom(atomListOfPlayers);

  const addPlayer = (playerName) => {
    const newState = { ...players };
    newState[playerName] = [0];
    setPlayers(newState);
  };

  return addPlayer;
}

export function useReturnRandomPlayer() {
  const [players] = useAtom(atomListOfPlayers);

  const returnRandomPlayer = () => {
    // Map player entries to an array with [playerName, adjustedWeight]
    // Ensuring weight is never 0 to avoid division by zero.
    const playerEntries = Object.entries(players).map(([player, [weight]]) => {
      // Adjust weight so lower numbers (higher priority) have higher selection chances.
      // Adding 1 to avoid division by zero and inverse to make lower numbers have higher weight.
      const adjustedWeight = 1 / (weight + 1);
      return [player, adjustedWeight];
    });

    // Calculate the total adjusted weight
    const totalAdjustedWeight = playerEntries.reduce(
      (acc, [, weight]) => acc + weight,
      0
    );

    // Generate a random selection value
    let random = Math.random() * totalAdjustedWeight;

    // Find and return the player based on the random selection value
    for (const [player, weight] of playerEntries) {
      random -= weight;
      if (random <= 0) {
        return player;
      }
    }

    // Fallback in case of an error or empty players object
    return null;
  };

  return returnRandomPlayer;
}

export function useIncreasePlayerPoint(selectedRandomPlayer) {
  const [listOfPlayers, setListOfPlayers] = useAtom(atomListOfPlayers);

  const increasePlayerPoint = (selectedRandomPlayer) => {
    // Copy the current state to avoid direct mutation
    const updatedListOfPlayers = { ...listOfPlayers };

    // Increase the player's number by one
    updatedListOfPlayers[selectedRandomPlayer][0] += 1;

    // Update the atom's state
    setListOfPlayers(updatedListOfPlayers);
  };

  return increasePlayerPoint;
}

export function useLongRoastName() {
  const returnLongRoastName = () => {
    const longRoastName =
      "The " +
      ADJEKTIVER[Math.floor(Math.random() * ADJEKTIVER.length)] +
      " " +
      NAVNEORD[Math.floor(Math.random() * NAVNEORD.length)] +
      " ";
    return longRoastName;
  };

  return returnLongRoastName;
}

export function useRandomToastTopic() {
  const returnRandomToastTopic = () => {
    const randomToastTopic = EMNER[Math.floor(Math.random() * EMNER.length)];
    return randomToastTopic;
  };

  return returnRandomToastTopic;
}

export function useRandomChance() {
  const returnRandomChance = () => {
    const randomChance = CHANCER[Math.floor(Math.random() * CHANCER.length)];
    return randomChance;
  };

  return returnRandomChance;
}
