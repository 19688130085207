// CountdownControlBar.js

import { React, useContext } from "react";

import Button from "@mui/material/Button";

import { CountdownContext } from "../Contexts/CountdownContext.js";

import useGameExecutor from "../Hooks/useGameExecutor";

const CountdownControlBar = () => {
  const {
    countdownState,
    setCountdownState,
    handleCountdownStart,
    handleCountdownPause,
    handleCountdownReset,
  } = useContext(CountdownContext);

  const { executeGame } = useGameExecutor();

  return (
    <div>
      {countdownState.isRunning ? (
        <Button variant="contained" onClick={handleCountdownPause}>
          Pause
        </Button>
      ) : (
        <Button variant="contained" onClick={handleCountdownStart}>
          Start
        </Button>
      )}
      <Button variant="contained" onClick={handleCountdownReset}>
        Reset
      </Button>
      <Button variant="contained" onClick={executeGame}>
        Skip to next game
      </Button>

      <input
        type="checkbox"
        defaultChecked={countdownState.isRepeating}
        onChange={(e) => {
          setCountdownState({
            ...countdownState,
            isRepeating: e.target.checked,
          });
        }}
      ></input>
      <input
        id="multiplier"
        type="range"
        min={1}
        max={10}
        defaultValue={countdownState.multiplier}
        onChange={(e) => {
          setCountdownState({
            ...countdownState,
            multiplier: parseInt(e.target.value),
          });
        }}
      ></input>
      <label htmlFor="multiplier">
        {" "}
        {countdownState.multiplier}-{countdownState.multiplier + 1} minutes
        timer
      </label>
    </div>
  );
};

export default CountdownControlBar;
