import "./App.css";
import "./Styles/styles.css";
import { useContext } from "react";
import Header from "./Components/layout/Header.jsx";
import Pie from "./Components/Pie.jsx";
import Games from "./Components/Games.jsx";
import GameExecutor from "./Components/GameExecutor.jsx";

import Players from "./Components/Players.jsx";
import CountdownControlBar from "./Components/CountdownControlBar.jsx";

import CssBaseline from "@mui/material/CssBaseline";

import useSyncDataToLocalStorage from "./Hooks/useSyncDataToLocalStorage.js";

import { CountdownProvider } from "./Contexts/CountdownContext.js"; // Adjust the path as necessary

export const App = () => {
  useSyncDataToLocalStorage();

  return (
    <>
      <CssBaseline />
      <CountdownProvider>
        <Header />
        <div id="app-container" className="z-10 w-full items-center divide-y">
          <Pie />
          <CountdownControlBar />
          <Games />
          <GameExecutor />
          <Players />
        </div>
      </CountdownProvider>
    </>
  );
};

export default App;

// TO DO
// - [x] Change data structure
// - [x] Pause/Play/Skip to next game functions
// - [x] Repeating checkbox that controls game repeating
// - [x] Does game repeat on repeating? Way for the GameExecutpr to pause and start the timer based on whether it is done executing or not
// - [ ] Make a nice looking interface that shows all the games that have a Not Null probability, how many times they've been played, and if you click on one, it will be played.
// - [x] Block safari
// - [x] Local TTS to replace Google when no key is found.
// = [x] Weighted selection of players
// - [ ] Language guessing game
// - [ ] Corruption, choose the next game
// - [ ] Play a song
// - [ ] Make it connect with Spotify
