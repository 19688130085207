import { useAtom } from "jotai";
import { atomListOfPlayers } from "../Components/state/playerAtoms.js";
import { useEffect } from "react";

export function useSyncDataToLocalStorage() {
  const [players] = useAtom(atomListOfPlayers);

  useEffect(() => {
    localStorage.setItem("players", JSON.stringify(players));
  }, [players]); // This effect runs whenever 'players' changes
}

export default useSyncDataToLocalStorage;
