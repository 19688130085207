// CountdownContext.js

import { createContext, useState, useEffect, useCallback } from "react";

export const CountdownContext = createContext();

export const CountdownProvider = ({ children }) => {
  const [countdownState, setCountdownState] = useState({
    initiatedTime: 60,
    currentTime: 60,
    isRunning: false,
    isRepeating: true,
    gameIsRunning: false,
    multiplier: 1,
  });

  const returnRandomTime = useCallback(() => {
    return Math.max(
      Math.floor(Math.random() * 60 * countdownState.multiplier) + 60,
      60 * countdownState.multiplier
    );
  }, [countdownState.multiplier]);

  useEffect(() => {
    let interval;

    if (countdownState.isRunning) {
      interval = setInterval(() => {
        // If Set to Repeating and time hits 0, set current time to initiated time and keep isRunning to true
        if (countdownState.isRepeating && countdownState.currentTime <= 0) {
          setCountdownState({
            ...countdownState,
            currentTime: returnRandomTime(),
          });

          // If not set to repeating and time hits 0, set running to false and clear interval
        } else if (
          !countdownState.isRepeating &&
          countdownState.currentTime <= 0
        ) {
          setCountdownState({ ...countdownState, isRunning: false });
          clearInterval(interval);

          // If no requirements are met, decrease current time by 1
        } else {
          setCountdownState({
            ...countdownState,
            currentTime: countdownState.currentTime - 1,
          });
        }
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [countdownState, returnRandomTime]);

  function handleCountdownStart() {
    if (countdownState.currentTime > 0) {
      setCountdownState({
        ...countdownState,
        isRunning: true,
      });
      return;
    }
    let newTime = returnRandomTime();
    setCountdownState({
      ...countdownState,
      initiatedTime: newTime,
      currentTime: newTime,
      isRunning: true,
    });
  }

  function handleCountdownPause() {
    setCountdownState({ ...countdownState, isRunning: false });
  }

  function handleCountdownReset() {
    setCountdownState({
      ...countdownState,
      isRunning: false,
      currentTime: returnRandomTime(),
    });
  }

  const values = {
    countdownState,
    setCountdownState,
    handleCountdownStart,
    handleCountdownPause,
    handleCountdownReset,
  };

  return (
    <CountdownContext.Provider value={values}>
      {children}
    </CountdownContext.Provider>
  );
};
