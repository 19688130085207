import React from "react";
import { useAtomValue } from "jotai";
import { atomCurrentGameText } from "./state/gameAtoms";

export const Games = () => {
  const currentGameText = useAtomValue(atomCurrentGameText);

  return (
    <>
      <div className="flex w-full justify-center items-center h-20">
        <p>{currentGameText}</p>
      </div>

      <div></div>
    </>
  );
};

export default Games;

// import AUDimportloveSong from "../sounds/loveSound.mp3";
// import AUDimportgameshowDrama from "../sounds/gameshowDrama.mp3";
// import { languageGameOBJ } from "../gameHelpers/languageGameOBJ.js";
// import {
//   setDisplayingSecretButton,
//   setDisplayingSecretDiv,
//   setDisplayingSecretTextbox,
//   setSecretButtonContent,
//   setSecretTextboxContent,
// } from "src/App.js";
// import { CHANCES } from "./gameHelpers/words.jsx";

// // Component
// // A list of all the functions that games need
// // A list of games and what functions they call

// // When a game is called from App.js it finds the game, and the game executes the functions it needs to do

// // List of functions
// // Speak Text

// export const GAMES = {
//   randomDrink: {
//     name: "Random Drink",
//     probability: 5,
//     play: (randomNameLong) => {
//       return Promise.resolve([
//         [
//           `Somebody is very thirsty. The thirsty person is ${randomNameLong} and you have to drink 5 sips. Mmhhh.`,
//         ],
//       ]);
//     },
//     increasesPlayerTurn: true,
//   },

//   everyoneDrink: {
//     name: "Everyone Drink",
//     probability: 5,
//     play: (randomNameLong) => {
//       return Promise.resolve([
//         [`Wow what a coincidence. We have drinks here! Everyone has to drink.`],
//       ]);
//     },
//     increasesPlayerTurn: false,
//   },

//   fingerOnTheGlass: {
//     name: "Finger on the Glass",
//     probability: 5,
//     play: (randomNameLong) => {
//       return Promise.resolve([
//         [
//           `Time to get the fingers out! It is the finger game. The person who starts is ${randomNameLong}`,
//         ],
//       ]);
//     },
//     increasesPlayerTurn: false,
//   },

//   opus: {
//     name: "Opus",
//     probability: 1,
//     play: (randomNameLong) => {
//       return Promise.resolve([
//         [`It is the time you've all been waiting for. Its time for opus. `],
//       ]);
//     },
//     increasesPlayerTurn: false,
//   },

//   yourFateIsInTheCards: {
//     name: "Your fate is in the cards",
//     probability: 3,
//     play: (randomNameLong) => {
//       const AUDgameshowDrama = new Audio(AUDimportgameshowDrama);

//       return new Promise((resolve) => {
//         AUDgameshowDrama.play();
//         AUDgameshowDrama.onended = () => {
//           resolve([
//             [
//               `What a magical evening. Someone is emitting magical energy. Your fate lies in the cards. ${randomNameLong}, you need to decide between black or red for a chance to take a shot or give a shot away`,
//             ],
//           ]);
//         };
//       });
//     },
//     increasesPlayerTurn: true,
//   },

//   sideAgainstSide: {
//     name: "Side against side",
//     probability: 2,
//     play: (randomNameLong) => {
//       return Promise.resolve([
//         [
//           `Now we see friends turn into enemies. It is time to play side against side flipcup.`,
//         ],
//       ]);
//     },
//     increasesPlayerTurn: false,
//   },

//   drinkWater: {
//     name: "Drink Water",
//     probability: 1,
//     play: (randomNameLong) => {
//       return Promise.resolve([
//         [
//           `It is time to drink water, you pig. The person who has to drink water is ${randomNameLong}`,
//         ],
//       ]);
//     },
//     increasesPlayerTurn: true,
//   },

//   cowboyChallenge: {
//     name: "Cowboy Challenge",
//     probability: 5,
//     play: (randomNameLong) => {
//       return Promise.resolve([
//         [
//           `Yeehaw my fellow cowbows. Saddle them up boys, rope them up boys. Time to battle someone to a vodka or water battle. The peorson challenging someone is ${randomNameLong} `,
//         ],
//       ]);
//     },
//     increasesPlayerTurn: true,
//   },

//   toastMaster: {
//     name: "Toast Master",
//     probability: 15,
//     play: (randomNameLong) => {
//       return Promise.resolve([
//         [
//           `Bing bong bing bong it is time for a new toastmaster. You decide what we drink and when we drink it. Let's hear a cheers for ${randomNameLong}`,
//         ],
//       ]);
//     },
//     increasesPlayerTurn: true,
//   },

//   spinner: {
//     name: "Spinner",
//     probability: 10,
//     play: (randomNameLong) => {
//       return Promise.resolve([
//         [
//           `Hello hello it is me. I miss you. My bed is colder when you're not around. Time to spin the spinning game. The person who spins is: ${randomNameLong}`,
//         ],
//       ]);
//     },
//     increasesPlayerTurn: false,
//   },

//   rockPaperScissors: {
//     name: "Rock Paper Scissors",
//     probability: 10,
//     play: (randomNameLong) => {
//       return Promise.resolve([
//         [
//           `Its very rocky, its very scissory, its very papery. Its time for rock paper scissors. The person to challenge someone to a shot is ${randomNameLong}`,
//         ],
//       ]);
//     },
//     increasesPlayerTurn: false,
//   },

//   vodkaAmor: {
//     name: "Vodka Amor",
//     probability: 5,
//     play: (randomNameLong) => {
//       const AUDloveSong = new Audio(AUDimportloveSong);

//       return new Promise((resolve) => {
//         AUDloveSong.play();
//         AUDloveSong.onended = () => {
//           resolve([
//             [
//               `Love is in the air. ${randomNameLong} has been announced as Vodka Amor. That means you get to choose two drinking buddies who have to drink together for the next 30 minutes`,
//             ],
//           ]);
//         };
//       });
//     },
//     increasesPlayerTurn: true,
//   },

//   languageGuesser: {
//     name: "Language Guesser",
//     probability: 5,
//     play: (randomNameLong) => {
//       let languageElement =
//         languageGameOBJ[Math.floor(Math.random() * languageGameOBJ.length)];

//       let message = languageElement[4];
//       let languageCode = languageElement[1];
//       let voice = languageElement[2];
//       let languageName = languageElement[3];

//       setDisplayingSecretDiv(true);

//       setDisplayingSecretTextbox(false);
//       setSecretTextboxContent(`The language is ${languageName}`);

//       setDisplayingSecretButton(true);
//       setSecretButtonContent("Show language");

//       return Promise.resolve([
//         [
//           `It is now time for the language game. Are you ready to guess the language I am speaking?. Let's try with a simple sentence. If we all guess it right, we don't have to drink`,
//         ],
//         [message, message, languageCode, voice],
//       ]);
//     },
//     increasesPlayerTurn: false,
//   },

//   whatAreTheOdds: {
//     name: "What are the odds",
//     probability: 7,
//     play: (randomNameLong, randomName) => {
//       let chosenChance = CHANCES[Math.floor(Math.random() * CHANCES.length)];
//       let chancePotential = Math.floor(Math.random() * 9) + 1;
//       let chanceActual = Math.floor(Math.random() * chancePotential);

//       return Promise.resolve([
//         [
//           `Hey everyone please give me attention. We're playing the chances. Hey ${randomName} what are the chances that you ${chosenChance}. It is 1 to ${chancePotential}. Counting down. 3. 2. 1. ${chanceActual}`,
//           `Hey everyone please give me attention. We're playing the chances. Hey ${randomName} what are the chances that you ${chosenChance}. It is 1 to ${chancePotential}. Counting down. 3. 2. 1.`,
//         ],
//       ]);
//     },
//     increasesPlayerTurn: true,
//   },
// };
