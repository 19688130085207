import React from "react";

const Header = () => {
  return (
    <div className="flex justify-center bg-blue-500 h-20 items-center">
      <h1 className="text-xl text-gray-900 dark:text-white">Vodkadamen</h1>
    </div>
  );
};

export default Header;
