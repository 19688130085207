import React from "react";

function isSafari() {
  const userAgent = window.navigator.userAgent.toLowerCase();
  const safari = /safari/.test(userAgent);
  const chrome = /chrome/.test(userAgent);

  return safari && !chrome;
}

const BrowserSupportCheck = ({ children }) => {
  if (isSafari()) {
    return (
      <>
        <div className="container flex justify-center items-center h-screen">
          <div>
            Safari not supported - please use another browser. It definitely
            works on Chrome, maybe on Firefox 🤠
          </div>
        </div>
      </>
    );
  }

  return <>{children}</>;
};

export default BrowserSupportCheck;
