import gameConfigs from "../games/gameConfigs.json";

const returnRandomGame = () => {
  // Calculate the total weight (sum of all probabilities)
  const totalWeight = gameConfigs.reduce(
    (sum, game) => sum + game.probability,
    0
  );

  if (totalWeight === 0) {
    throw new Error("No games with positive probability.");
  }

  // Generate a random number between 0 and the total weight
  let randomNum = Math.random() * totalWeight;

  // Find and return the game that corresponds to the randomNum
  for (const game of gameConfigs) {
    if (randomNum < game.probability) {
      return game;
    }
    randomNum -= game.probability;
  }

  // Fallback in case of rounding errors, though unlikely
  return gameConfigs[gameConfigs.length - 1];
};

export default returnRandomGame;
