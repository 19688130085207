import { useContext, useState, useEffect, useCallback } from "react";
import { useAtom } from "jotai";
import {
  atomGameStatus,
  atomCurrentGameText,
} from "../Components/state/gameAtoms";
import { atomListOfPlayers } from "../Components/state/playerAtoms";
import { CountdownContext } from "../Contexts/CountdownContext";
import {
  useReturnRandomPlayer,
  useLongRoastName,
  useRandomToastTopic,
  useRandomChance,
  useIncreasePlayerPoint,
} from "../Hooks/gameManagement";
import returnRandomGame from "../gameHelpers/returnRandomGame";

function useGameExecutor() {
  const [gameStatus, setGameStatus] = useAtom(atomGameStatus);
  const [listOfPlayers] = useAtom(atomListOfPlayers);
  const [, setDisplayText] = useAtom(atomCurrentGameText);
  const [currentlyRunning, setCurrentlyRunning] = useState(false);

  const { handleCountdownStart, handleCountdownPause, handleCountdownReset } =
    useContext(CountdownContext);

  const randomPlayer = useReturnRandomPlayer();
  const longRoastName = useLongRoastName();
  const randomToastTopic = useRandomToastTopic();
  const randomChance = useRandomChance();
  const increasePlayerPoint = useIncreasePlayerPoint();

  const updateDisplayText = useCallback(
    (text) => {
      setDisplayText(text);
    },
    [setDisplayText]
  );

  const executeGame = useCallback(async () => {
    if (currentlyRunning) {
      console.log("Game already running, skipping execution");
      return; // Optionally handle or display an error message
    }

    setCurrentlyRunning(true);
    handleCountdownPause();
    setGameStatus("running");

    const game = returnRandomGame();
    const selectedRandomPlayer = randomPlayer();
    let selectedRandomSecondPlayer = randomPlayer();
    while (
      selectedRandomPlayer === selectedRandomSecondPlayer &&
      listOfPlayers.length > 1
    ) {
      selectedRandomSecondPlayer = randomPlayer();
    }

    const selectedLongRoastName = longRoastName();
    const selectedRandomToastTopic = randomToastTopic();
    const selectedRandomChance = randomChance();

    if (game.increasesPlayerPlays && listOfPlayers[selectedRandomPlayer]) {
      increasePlayerPoint(selectedRandomPlayer);
    }

    // Execute game functions sequentially
    for (const gameFunction of game.functions) {
      const { functionName, params } = gameFunction;
      const processedParams = {
        ...params,
        updateDisplayText,
        text:
          params.text &&
          params.text.replace(
            /\{[^}]+\}/g,
            (match) =>
              ({
                "{randomPlayer}": selectedLongRoastName + selectedRandomPlayer,
                "{randomSecondPlayer}": selectedRandomSecondPlayer,
                "{randomToastTopic}": selectedRandomToastTopic,
                "{randomChance}": selectedRandomChance,
              }[match] || match)
          ),
      };

      try {
        const executeFunction = (await import(`../games/${functionName}.js`))
          .default;
        console.log("Calling the function: ", functionName);
        await executeFunction(processedParams);
        console.log(
          `The function with the name ${functionName} has been resolved`
        );
      } catch (error) {
        console.error("Error executing game function:", error);
        setGameStatus("failed");
        setCurrentlyRunning(false);
        return; // Exit the loop on error
      }
    }

    // If all functions execute successfully
    setGameStatus("completed");
    handleCountdownReset();
    handleCountdownStart();
    setCurrentlyRunning(false);
  }, [
    currentlyRunning,
    setGameStatus,
    randomPlayer,
    longRoastName,
    randomToastTopic,
    randomChance,
    increasePlayerPoint,
    handleCountdownStart,
    handleCountdownPause,
    handleCountdownReset,
    listOfPlayers,
    updateDisplayText,
  ]);

  useEffect(() => {
    if (gameStatus === "idle") {
      executeGame();
    }
  }, [gameStatus, executeGame]);

  return { executeGame, gameStatus };
}

export default useGameExecutor;
