import React from "react";
import { useAtom } from "jotai";
import { atomListOfPlayers } from "./state/playerAtoms.js";
import { useRemovePlayer, useAddPlayer } from "../Hooks/gameManagement.js";

const Players = () => {
  const [players, setPlayers] = useAtom(atomListOfPlayers);
  const removePlayer = useRemovePlayer();
  const addPlayer = useAddPlayer();

  // Handler for changing a player's score directly
  const handleScoreChange = (player, newScore) => {
    const updatedPlayers = {
      ...players,
      [player]: [parseInt(newScore, 10) || 0], // Ensure the input is treated as a number; default to 0 if NaN
    };
    setPlayers(updatedPlayers);
  };

  // Handler for incrementing a player's score
  const incrementScore = (player) => {
    const updatedPlayers = {
      ...players,
      [player]: [players[player][0] + 1],
    };
    setPlayers(updatedPlayers);
  };

  return (
    <>
      <div className="flow-root max-w-96">
        <ul className="divide-y divide-gray-200 dark:divide-gray-700">
          {Object.keys(players).map((player) => {
            return (
              <li className="py-3 sm:py-4" key={player}>
                <div className="flex justify-between items-center w-full space-x-4">
                  <div className="flex-1 min-w-0">
                    <p className="text-sm font-medium text-gray-900 truncate dark:text-black">
                      {player}
                    </p>
                  </div>
                  <div className="inline-flex items-center">
                    <input
                      className="text-sm font-medium text-gray-900 dark:text-black mx-2"
                      type="number"
                      value={players[player][0]}
                      onChange={(e) =>
                        handleScoreChange(player, e.target.value)
                      }
                      style={{ width: "60px" }}
                    />
                    <button
                      onClick={() => incrementScore(player)}
                      className="px-2 py-1 border rounded"
                    >
                      +1
                    </button>
                  </div>
                  <button
                    className="shrink-0 ml-auto text-right px-2 py-1 border rounded"
                    onClick={() => {
                      removePlayer(player);
                    }}
                  >
                    x
                  </button>
                </div>
              </li>
            );
          })}
        </ul>
        <div className="flex justify-between items-center w-full">
          <input
            className="w-1/2 border px-2 py-1 rounded"
            type="text"
            placeholder="Add player"
            onKeyDown={(e) => {
              if (e.key === "Enter" && e.target.value.trim() !== "") {
                addPlayer(e.target.value);
                e.target.value = "";
              }
            }}
          />
        </div>
      </div>
    </>
  );
};

export default Players;
